import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Country } from 'react-phone-number-input';
import { FormType } from '../../../../models/FormTypes';
import ConditionsProperties from '../../../form-builder/ConditionsProperties';
import { EditorProps } from '../../../form-builder/FormBuilderTypes';
import PreviewProperties from '../../../form-builder/PreviewProperties';
import RiskProperties from '../../../form-builder/RiskProperties';
import CountryCodeSelect from '../../../shared/CountryCodeSelect';
import ActionEditorBase from '../ActionEditorBase';

const PhoneNumberEditor: FC<EditorProps> = (props) => {
  const { action, patchData, patchAction, form } = props;
  const { countryCode } = action?.data || {};
  const { t } = useTranslation('form-builder');

  const onCountryCodeCange = (value: Country) => {
    patchData({ countryCode: value });
  };

  useEffect(() => {
    if (!action?.data?.countryCode) {
      patchData({ countryCode: 'DE' });
    }
  }, [action?.data, patchData]);

  return (
    <div data-cy="phone-number-editor">
      <ActionEditorBase {...props}>
        <div className="mt-4 flex items-center justify-between">
          <div>{t('action-properties.common.internation-code')}</div>
          <div>
            <CountryCodeSelect onChange={onCountryCodeCange} value={countryCode} />
          </div>
        </div>
      </ActionEditorBase>

      <ConditionsProperties {...props} />

      <PreviewProperties {...props} enableDocumentNumbering />

      {(form.type === FormType.Asset || form.type == FormType.SubForm || form.type == FormType.SubFormWithApproval) && (
        <RiskProperties action={action} patchAction={patchAction} form={form} />
      )}
    </div>
  );
};

export default PhoneNumberEditor;
